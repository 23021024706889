<template>
  <div>
    <portal to="destination">
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link" @click="exportExcel">
          <span class="navi-icon">
            <i class="flaticon2-calendar-8"></i>
          </span>
          <span class="navi-text">Exportar a excel</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>

      <!-- CANCELED REPORT -->
      <b-dropdown-text>
        <div>REPORTE DE CANCELADOS</div>
        <label class="typo__label">Desde</label>
        <b-input type="date" v-model="form.from"></b-input>
      </b-dropdown-text>
      <b-dropdown-text>
        <label class="typo__label">Hasta</label>
        <b-input type="date" v-model="form.to"></b-input>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-footer">
        <a
          class="btn btn-light-primary font-weight-bolder btn-sm"
          href="#"
          @click="downloadCanceledReport()"
          >Descargar r. cancelados</a
        >
      </b-dropdown-text>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card
          border-variant="primary"
          header="Reporte de citas"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row mb-5">
              <div class="col-md-2">
                <label class="typo__label">Desde</label>
                <b-input type="date" v-model="form.from"></b-input>
              </div>
              <div class="col-md-2">
                <label class="typo__label">Hasta</label>
                <b-input type="date" v-model="form.to"></b-input>
              </div>
              <div class="col-md-3">
                <label class="typo__label">Usuario</label>
                <b-form-select
                  v-model="form.cashier"
                  :options="cashiers"
                  value-field="id"
                  text-field="full_name"
                ></b-form-select>
              </div>
              <div class="col-md-3">
                <label class="typo__label">Profesional</label>
                <b-form-select
                  v-model="form.doctor"
                  :options="doctors"
                  value-field="id"
                  text-field="full_name"
                ></b-form-select>
              </div>
              <div class="col-md-2">
                <b-button variant="success" @click="getAppointments"
                  >Buscar
                </b-button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-table
                  :items="total_appointments"
                  :fields="fields"
                  head-variant="light"
                  caption-top
                >
                  <template #cell(doctor_percentage)="data">
                    {{ data.item.doctor_percentage.toFixed(2) }}
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      class="mb-2 mr-1"
                      variant="primary"
                      @click="printAppointment(data.item)"
                    >
                      <b-icon icon="printer" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button
                      size="sm"
                      class="mb-2 mr-1"
                      variant="danger"
                      @click="deleteAppointment(data.item)"
                    >
                      <b-icon icon="trash" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button
                      variant="primary"
                      @click="createMedicalPrescription(data.item.id)"
                      title="Crear medicamentos y diagnósticos"
                      size="sm"
                      class="mb-2 mr-1"
                    >
                      <b-icon
                        icon="clipboard-check"
                        aria-label="Crear medicamentos y diagnósticos"
                      ></b-icon>
                    </b-button>
                  </template>
                </b-table>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import XLSX from "xlsx";

export default {
  name: "ListAppointment",
  data() {
    return {
      form: {
        from: "",
        to: "",
        cashier: null,
        doctor: null
      },
      cashiers: [],
      doctors: [],
      appointments: [],
      lab_appointments: [],
      total_appointments: [],
      report_appointments: [],
      fields: [
        { key: "created_at", label: "Registro", sortable: true },
        { key: "specialty", label: "Especialidad" },
        { key: "doctor", label: "Profesional" },
        { key: "person", label: "Paciente" },
        { key: "doctor_percentage", label: "Comisión" },
        { key: "price", label: "Precio" },
        { key: "actions", label: "Acciones" }
      ]
    };
  },
  methods: {
    downloadCanceledReport() {
      ApiService.get(
        "appointment/report/deleted",
        `?from=${this.form.from}&to=${this.form.to}`
      ).then(res => {
        const total_canceled = [
          ...res.data.appointments,
          ...res.data.lab_appointments
        ];
        this.exportCanceledToExcel(total_canceled);
      });
    },
    createMedicalPrescription(itemId) {
      this.$router.push({
        name: "clinic_medical_prescription_create",
        params: { appointmentId: itemId }
      });
    },
    exportCanceledToExcel: function(total_canceled) {
      //Extract laboratory appointments
      let buffer = [];
      total_canceled.forEach(index => {
        console.log("ANALISIS: ", index);
        if (
          index.specialty === "LABORATORIO" ||
          index.specialty === "ECOGRAFIA" ||
          index.specialty === "RADIOLOGIA"
        ) {
          index.analysis_details.forEach(index2 => {
            console.log(index2);
            let obj = {
              FECHA: index.created_at,
              PACIENTE: index.person,
              ESPECIALIDAD: index.specialty,
              "PROFESIONAL DE LA SALUD": index.doctor,
              PRECIO: index2.price,
              EXAMEN: index2.name,
              "CANCELADO POR": index.user
            };
            buffer.push(obj);
          });
        } else {
          let obj = {
            FECHA: index.created_at,
            PACIENTE: index.person,
            ESPECIALIDAD: index.specialty,
            "PROFESIONAL DE LA SALUD": index.doctor,
            PRECIO: parseFloat(index.price),
            "CANCELADO POR": index.user
          };
          buffer.push(obj);
        }
      });

      console.log("BUFFERRRRR ", buffer);

      let data = XLSX.utils.json_to_sheet(buffer);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte cancelados";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    deleteAppointment(item) {
      const result = confirm(
        "¿Estás seguro de que quieres eliminar esta cita?"
      );
      if (result === true) {
        if (item.hasOwnProperty("analysis_details")) {
          ApiService.delete(`/laboratory-appointment/${item.id}/`).then(() => {
            this.getAppointments();
          });
        } else {
          ApiService.delete(`/appointment/${item.id}/`).then(() => {
            this.getAppointments();
          });
        }
      }
    },
    getDoctors() {
      ApiService.get(`doctor`).then(res => {
        this.doctors = res.data;
        this.doctors.unshift({ id: null, full_name: "TODOS" });
      });
    },
    exportExcel: function() {
      //Extract laboratory appointments
      let buffer = [];
      this.total_appointments.forEach(index => {
        console.log("ANALISIS: ", index);
        if (index.hasOwnProperty("analysis_details")) {
          index.analysis_details.forEach(index2 => {
            console.log(index2);
            let obj = {
              FECHA: index.created_at,
              PACIENTE: index.person,
              ESPECIALIDAD: index.specialty,
              "PROFESIONAL DE LA SALUD": index.doctor,
              PRECIO: index2.price,
              EXAMEN: index2.name,
              CANTIDAD: index2.quantity,
              TOTAL: index2.total
            };
            buffer.push(obj);
          });
        } else {
          let obj = {
            FECHA: index.created_at,
            PACIENTE: index.person,
            ESPECIALIDAD: index.specialty,
            "PROFESIONAL DE LA SALUD": index.doctor,
            PRECIO: parseFloat(index.price),
            CANTIDAD: 1,
            TOTAL: parseFloat(index.price)
          };
          buffer.push(obj);
        }
      });

      console.log("BUFFERRRRR ", buffer);

      let data = XLSX.utils.json_to_sheet(buffer);
      const workbook = XLSX.utils.book_new();
      const filename = "Clinic";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    printAppointment(appointment) {
      if (appointment.hasOwnProperty("analysis_details")) {
        console.log("ES AUXILIAR");
        const ruc = process.env.VUE_APP_ENTERPRISE_RUC;
        this.axios.post(
          `http://localhost:3030/laboratory/${ruc}/`,
          appointment
        );
      } else {
        console.log("NO NO NO NO ES AUXILIAR");
        ApiService.get(`appointment/${appointment.id}/print`).then(res2 => {
          const ruc = process.env.VUE_APP_ENTERPRISE_RUC;
          this.axios.post(`http://localhost:3030/${ruc}/`, res2.data);
        });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Éxito al guardar", {
        title: `Todo bien`,
        variant: variant,
        solid: true
      });
    },
    getCurrentDateTimeFormat() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      let formatted = yyyy + "-" + mm + "-" + dd;
      console.log(formatted);
      this.form.from = formatted;
      this.form.to = formatted;
    },
    getAppointments() {
      ApiService.get(
        "appointment/report",
        `?from=${this.form.from}&to=${this.form.to}&cashier=${this.form.cashier}&doctor=${this.form.doctor}`
      ).then(res => {
        const buffer = [...res.data.appointments, ...res.data.lab_appointments];
        this.total_appointments = buffer.sort((a, b) => {
          return a.created_at > b.created_at ? 1 : -1;
        });
      });
    },
    getCashiers() {
      ApiService.get("user/search/role", `?role=CAJERO`).then(res => {
        this.cashiers = res.data;
        this.cashiers.unshift({ id: null, full_name: "TODOS" });
      });
    }
  },
  mounted() {
    this.getCashiers();
    this.getCurrentDateTimeFormat();
    this.getAppointments();
    this.getDoctors();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Reporte de citas" }]);
  }
};
</script>

<style scoped>
.card-header {
  padding: 1rem 2.25rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
